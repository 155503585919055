'use client'
import React, { useContext, useRef, useState } from 'react'
import axios from 'axios'
import { LoginContext } from '@/Context/LoginContext'
import { useRouter } from 'next/navigation'
import 'dotenv/config'

const LoginPage = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const buttonClickLoad = useRef<HTMLButtonElement | null>(null)
  const { isAuthenticated, setAuthenticated } = useContext(LoginContext)
  const router = useRouter()
  const payloadUrl = process.env.NEXT_PUBLIC_PAYLOAD_URL_PROD

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    buttonClickLoad.current!.disabled = true

    try {
      const response = await axios.post(`${payloadUrl}users/login`, {
        email,
        password,
      })

      // Handle success (e.g., store the token, redirect, etc.)
      if (response.data.token) {
        setAuthenticated(true)
        router.push('/home')
      }
    } catch (err) {
      setError('Invalid email or password')
    }
    buttonClickLoad.current!.disabled = false
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 text-black">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-lg">
        <h1 className="text-4xl font-semibold text-center text-gray-800">Login</h1>
        {error && <p className="text-red-500 text-center">{error}</p>}
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-4 py-2 mt-1 border rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-700 focus:border-gray-700"
              placeholder="Enter your email"
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full px-4 py-2 mt-1 border rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-700 focus:border-gray-700"
              placeholder="Enter your password"
            />
          </div>
          <button
            ref={buttonClickLoad}
            type="submit"
            className="w-full px-4 py-2 text-lg font-medium text-white bg-gradient-to-r from-gray-700 to-black rounded-lg shadow hover:from-gray-800 hover:to-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  )
}

export default LoginPage
